import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  background-color: #ccc;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 9998;
  opacity: 0.5;
`;

export const Container = styled.div`
  position: fixed;
  width: 100%;
  max-width: 490px;
  z-index: 9999;
  top: 25px;
  left: calc(50% - 245px);
  background-color: #ffffff;
  box-shadow: 0px 0px 6px #0000001a;
  border-radius: 4px;
  padding: 0;
  max-height: 96vh;
  overflow: auto;

  @media (max-width: 425px) {
    max-width: 400px;
    left: calc(50% - 200px);
    top: 2vh;
    overflow-y: scroll;
    max-height: 96vh;
  }

  @media (max-width: 375px) {
    max-width: 350px;
    left: calc(50% - 175px);
  }
  @media (max-width: 320px) {
    max-width: 300px;
    left: calc(50% - 150px);
  }
`;

export const Header = styled.div`
  background-color: #001c72;
  color: #fff;
  padding: 25px 25px 18px;
  font-size: 32px;
  text-align: center;
  font-family: 'Utopia Std';

  @media (max-width: 425px) {
    font-size: 20px;
  }
`;

export const Body = styled.div`
  font-size: 18px;
  position: relative;
  padding: 25px;
  & > p {
    font-size: 16px;
    font-family: 'Utopia Std';
  }
`;

export const GrayContainer = styled.div`
  background-color: #f5f7f9;
  display: flex;
  flex-direction: row;
  padding: 10px;
  margin-bottom: 20px;
`;

export const Left = styled.div`
  width: 100px;
  height: 100px;
  & img {
    max-width: 100px;
  }
`;
export const Right = styled.div`
  padding-top: 15px;
  padding-left: 15px;
  & h3 {
    font-size: 32px;
    font-family: Utopia Std;
    color: #001c72;
    padding: 0;
    margin: 0;
    margin-bottom: 10px;
    font-weight: normal;

    @media (max-width: 425px) {
      font-size: 20px;
      margin-top: 10px;
    }
  }
  & p {
    padding: 0;
    margin: 0;
    font-size: 16px;
  }
  & p > span {
    color: #bc2e3e;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px;

  & .right {
    & h3 {
      font-size: 16px;
      margin-top: 10px;
    }
    & p {
      font-size: 14px;
    }
  }
`;

export const FirstDelivery = styled.div`
  font-family: Utopia Std;
  padding: 15px 0 15px;
  font-style: italic;
  font-size: 20px;
  border-bottom: 1px solid #dcdcdc;
  & > span {
    float: right;
    font-style: normal;
  }
`;

export const Then = styled.div`
  font-family: Utopia Std;
  padding: 15px 0;
  font-style: italic;
  margin-bottom: 5px;
  font-size: 20px;
  & > span {
    float: right;
    font-style: normal;
  }
`;

export const CloseButton = styled.div`
  width: 30px;
  height: 30px;
  background-color: #001c72;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  padding-top: 17px;
  padding-left: 1px;
  position: absolute;
  line-height: 0;
  right: 15px;
  top: 22px;
  cursor: pointer;
  font-size: 30px;
  line-height: 0.2;
  font-weight: bold;

  @media (max-width: 425px) {
    top: 15px;
  }
`;

export const SubmitButton = styled.div`
  & > button {
    font-size: 16px;
    font-family: 'Neuzeit Grotesk', sans-serif;
    letter-spacing: 3px;
    line-height: normal;
    font-weight: normal;
    box-shadow: none;
    padding-top: 20px;
    padding-bottom: 16px;
  }
  & > button:hover {
    padding-top: 20px;
    padding-bottom: 16px;
    & > span {
      top: 0px;
    }
  }
  & > button > span {
    width: 100%;
    font-weight: normal;
    font-size: 16px;
  }
  & > button::after {
    display: none;
  }
`;
