import React, { useContext } from 'react';
import * as S from './CrossSellPopup.styles';
import { ProductData } from '../../utils/productPrice';
import FunnelContext from '../../context/FunnelContext';
import loadable from '@loadable/component';

const AddToCartButton = loadable(() => import('./AddToCartButtonV1'));

export const CrossSellPopup = props => {
  const { show, setShow, endpointUrl, firstDelivery, thenDelivery } = props;

  const {
    currency,
    currentCategory,
    currentProduct,
    extraObjects
  } = useContext(FunnelContext);

  const hideFreeTrial =
    !extraObjects.active_subscription_special_offer || false;
  const crossSellDiscountPercentage = () => {
    switch (extraObjects.active_subscription_discount_type) {
      case 'DISCOUNT_35':
        return 35;
      case 'DISCOUNT_40':
        return 40;
      case 'DISCOUNT_50':
        return 50;
      case 'DISCOUNT_55':
        return 55;
      case 'DISCOUNT_FREE_UNIT':
        return 0;
      case 'DISCOUNT_25':
      default:
        return 25;
    }
  };

  const currencySymbol = currency?.symbol || '$';

  const [productData] = ProductData({
    currentCategory: currentCategory,
    currentProduct: currentProduct
  });

  const { checkoutData } = productData;

  const { active_subscription } = extraObjects;
  if (show) {
    return (
      <>
        <S.Container>
          <S.Header>
            <S.CloseButton onClick={() => setShow(false)}>
              &times;
            </S.CloseButton>
            Please Confirm
          </S.Header>
          <S.Body>
            <S.GrayContainer>
              <S.Left>
                <img src={checkoutData.image} alt="addon product" />
              </S.Left>
              <S.Right>
                <h3>{productData.display_title}</h3>

                {hideFreeTrial && (
                  <p>
                    {currencySymbol}
                    {checkoutData.discounted_price.toFixed(2)}{' '}
                    <span className="red">
                      (save {crossSellDiscountPercentage()}%)
                    </span>
                  </p>
                )}

                {!hideFreeTrial && (
                  <p>
                    Free Trial{' '}
                    <span className="red">
                      (then {currencySymbol}
                      {checkoutData.discounted_price.toFixed(2)})
                    </span>
                  </p>
                )}
              </S.Right>
            </S.GrayContainer>

            <p>Will be added to this subscription:</p>

            <S.FlexContainer>
              <S.Left>
                <img
                  src={active_subscription?.image}
                  alt="active subscription"
                />
              </S.Left>
              <S.Right className="right">
                <h3>
                  {active_subscription?.mainProductName || `Main Product Title`}
                </h3>
                <p>
                  {currencySymbol}
                  {active_subscription?.totalAmount.toFixed(2) || '0.00'}
                </p>
              </S.Right>
            </S.FlexContainer>

            {hideFreeTrial && (
              <S.FirstDelivery>
                <span>
                  {currencySymbol}
                  {firstDelivery || checkoutData.discounted_price.toFixed(2)}
                </span>
                First delivery:
              </S.FirstDelivery>
            )}

            {!hideFreeTrial && (
              <S.FirstDelivery>
                <span>{currencySymbol}0.00</span>
                First delivery:
              </S.FirstDelivery>
            )}

            <S.Then>
              <span>
                {currencySymbol}
                {thenDelivery || checkoutData.discounted_price.toFixed(2)}
              </span>
              Then:
            </S.Then>
            <S.SubmitButton>
              <AddToCartButton
                buttonText="Confirm &amp; PROCEED"
                backgroundColor="#001C72"
                pageDesign="design2"
                triggerCheckoutButton="update-active-subscription"
                crossSellEndpointApiUrl={endpointUrl}
              />
            </S.SubmitButton>
          </S.Body>
        </S.Container>
        <S.Wrapper onClick={() => setShow(false)} />
      </>
    );
  } else {
    return ``;
  }
};

export default CrossSellPopup;
